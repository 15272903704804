import './App.css';
import logo from './media/logo.png';
import img1 from './media/img1.jpeg';
import img2 from './media/img2.jpeg';
import img3 from './media/img3.jpeg';

function App() {
  return (
    <div className="App" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    }}>
      <div className={'main-container'}>
        <div className={'header'}>
            <img className={'header__logo'} src={logo} alt={'logo'}/>
        </div>
          <div className={'page-container'}>
                <h1>
                    Футбол:<br/> Українці в США
                </h1>
              <br/>
              <img src={img1} alt={'img1'}/>
              <br/>
                <p>
                    Артем Мотузний, відомий у вузьких колах хлопець з глибинки Києва, переїхав в маленьке місто в США у Lenoir City, Tennessee. Почав ходити там в школу та грати за шкільну команду з футболу. За сезон 2022-23 Артем забив 2 м'ячі та віддав 2 гольові передачі.
                </p>
                <br/>
              <img src={img2} alt={'img2'}/>
              <br/>
                <p>
                    За сезон 2023-24 він провів 12 ігор, забив 3 м'ячі та потрапив у топ-5 бомбардирів своєї команди та топ-100 своєї ліги. Артем вже закінчив школу і прагне продовжити навчання в коледжі, а також грати в футбол.
                </p>
                <br/>
              <img src={img3} alt={'img3'}/>
              <br/>
                <p>
                    Бажаємо Артему успіху!
                </p>
          </div>
          <br/>
      </div>
    </div>
  );
}

export default App;
